.sign_up_cargo_officer {
  min-height: 100vh;
  background: #fff;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  &__content {
    display: block;
    position: relative;
    width: 100%;
    max-width: 1600px;
    padding: 40px 20px;

    @include breakpoint(midscreen) {
      padding: 40px;
    }
  }
  &__heading {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  &__logo {
    width: 100%;
    max-width: 380px;
    margin-bottom: 20px;
  }
  &__title {
    text-align: center;
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: 500;
  }
  &__form {
    display: flex;
    justify-content: center;
  }
  &__submit_button_wrapper {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
}
