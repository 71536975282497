.billings_cargo_order_listing {
  &__pagination_wrapper {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
  }

  .billings_cargo_order_listing_tools {
    display: flex;
    justify-content: space-between;
    position: relative;
  }

  .billings_cargo_order_listing_cargo_company_select {
    width: 300px !important;
    max-width: 100%;
  }

  .billings_cargo_order_listing_table_status {
    width: 5px;
    height: 40px;

    &.created {
      background-color: $createdStatusColor;
    }

    &.accepted {
      background-color: $acceptedStatusColor;
    }

    &.rejected {
      background-color: $rejectedStatusColor;
    }

    &.reopened {
      background-color: $reopenedStatusColor;
    }

    &.reopen_request_sent {
      background-color: $reopenRequestStatusColor;
    }
  }
}

.billings_cargo_order_listing_billings_accept {
  position: fixed;
  bottom: 100px;
  right: 100px;
  width: 300px;
  background-color: #fff;
  border-radius: $borderRadius;
  box-shadow: 0px 0px 30px 1px #c9c9c9;
  padding: 10px 12px;

  &__heading {
    font-size: 18px;
  }

  &__content {
    padding-top: 10px;
  }
}
