.listing_filter_badge {
  border-color: #ececec;
  display: flex;
  height: min-content;
  padding: 3px 6px;
  max-width: 140px;

  &__text {
    font-size: 14px;
    padding-right: 8px;
    width: calc(100% - 16px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__button {
    padding: 2px;
    height: 16px;
    width: 16px;
  }
}
