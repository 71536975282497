.billings_taxi_unbilled_private_order_listing {
  &__pagination_wrapper {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
  }

  .billings_taxi_unbilled_private_order_listing_tools {
    display: flex;
    justify-content: space-between;
    position: relative;
  }

  .billings_taxi_unbilled_private_order_listing_taxi_corporation_select {
    width: 300px !important;
    max-width: 100%;
  }

  .billings_taxi_unbilled_private_order_listing_table_status {
    width: 5px;
    height: 40px;

    &.finished {
      background-color: $finishedStatusColor;
    }
    &.cancelled {
      background-color: $stoppedStatusColor;
    }
  }
}
