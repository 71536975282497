.billings_taxi_route_listing {
  &__pagination_wrapper {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
  }

  .billings_taxi_route_listing_tools {
    display: flex;
    justify-content: space-between;
    position: relative;
  }

  .billings_taxi_route_listing_taxi_corporation_select {
    width: 300px !important;
    max-width: 100%;
  }
}
