.delegation_address_add_modal {
  &_map {
    height: 100%;
    width: 100%;
    min-height: 300px;
  }

  &_field {
    width: 100%;
    height: 100%;
    position: relative;
  }
}
