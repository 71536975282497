$noticeBoxBorderRadius: $borderRadius;

$noticeBoxPrimaryBackgroundColor: desaturate($primaryColor, 10%);
$noticeBoxPrimaryColor: #fff;

$noticeBoxBrandBackgroundColor: desaturate($brandColor, 10%);
$noticeBoxBrandColor: #fff;

$noticeBoxDangerBackgroundColor: desaturate($dangerColor, 10%);
$noticeBoxDangerColor: #fff;

$noticeBoxSuccessBackgroundColor: desaturate($successColor, 10%);
$noticeBoxSuccessColor: #fff;

$noticeBoxWarningBackgroundColor: desaturate($warningColor, 10%);
$noticeBoxWarningColor: #fff;

$noticeBoxInfoBackgroundColor: desaturate($infoColor, 10%);
$noticeBoxInfoColor: #fff;

.notice_box {
  padding: 10px 12px;
  border-radius: $noticeBoxBorderRadius;
  position: relative;

  &.primary {
    background-color: $noticeBoxPrimaryBackgroundColor;
    color: $noticeBoxPrimaryColor;

    .notice_box_content {
      color: $noticeBoxPrimaryColor;
      * {
        color: $noticeBoxPrimaryColor;
      }
    }
  }

  &.brand {
    background-color: $noticeBoxBrandBackgroundColor;
    color: $noticeBoxBrandColor;

    .notice_box_content {
      color: $noticeBoxBrandColor;
      * {
        color: $noticeBoxBrandColor;
      }
    }
  }

  &.danger {
    background-color: $noticeBoxDangerBackgroundColor;
    color: $noticeBoxDangerColor;

    .notice_box_content {
      color: $noticeBoxDangerColor;
      word-wrap: break-word;
      * {
        color: $noticeBoxDangerColor;
        word-wrap: break-word;
      }
    }
  }

  &.success {
    background-color: $noticeBoxSuccessBackgroundColor;
    color: $noticeBoxSuccessColor;

    .notice_box_content {
      color: $noticeBoxSuccessColor;
      * {
        color: $noticeBoxSuccessColor;
      }
    }
  }

  &.warning {
    background-color: $noticeBoxWarningBackgroundColor;
    color: $noticeBoxWarningColor;

    .notice_box_content {
      color: $noticeBoxWarningColor;
      * {
        color: $noticeBoxWarningColor;
      }
    }
  }

  &.info {
    background-color: $noticeBoxInfoBackgroundColor;
    color: $noticeBoxInfoColor;

    .notice_box_content {
      color: $noticeBoxInfoColor;
      * {
        color: $noticeBoxInfoColor;
      }
    }
  }

  .notice_box_content {
    font-size: 14px;
    white-space: normal;

    * {
      white-space: normal;
      font-size: 14px;
    }
  }
}
