.heading {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;

  .heading_title {
    font-size: 1.5rem;
    font-weight: 500;
    width: 100%;
  }

  .heading_actions {
    display: flex;
    height: min-content;

    & > :not(:first-child) {
      margin-left: 5px;
    }
  }
}
