.delegation-listing {
  &_table {
    &_status {
      width: 5px;
      height: 40px;
      &.undownloaded {
        background-color: $undownloadedStatusColor;
      }
      &.downloaded {
        background-color: $downloadedStatusColor;
      }
      &.deleted {
        background-color: $deletedStatusColor;
      }
    }

    &_edit {
      path {
        fill: $infoActiveColor;
      }
    }

    &_cancel {
      path {
        fill: $dangerActiveColor;
      }
    }

    &_active {
      path {
        fill: $successActiveColor;
      }
    }

    &_message {
      path {
        fill: #fab513;
      }
    }

    &_label {
      path {
        fill: $fontPrimaryColor;
      }
    }
  }
}

.delegation_listing_table_route {
  display: block;
  white-space: normal;
}

.delegation_listing_action_modal {
  position: fixed;
  bottom: 100px;
  right: 100px;
  width: 300px;
  background-color: #fff;
  border-radius: $borderRadius;
  box-shadow: 0px 0px 30px 1px #c9c9c9;
  padding: 10px 12px;

  &__heading {
    font-size: 18px;
  }

  &__content {
    padding-top: 10px;
  }
}
