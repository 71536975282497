.planning_order_order_details_route {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin-top: -8px;

  span {
    font-size: 14px;
    color: rgb(48, 54, 87);
    min-width: 65px;
    padding: 3px 7px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    @include breakpoint(xxlargescreen) {
      min-width: 110px;
      max-width: unset;
    }
  }

  &__waypoint {
    display: flex;
    padding: 2px 0;

    &-name {
      flex-basis: 100%;
      display: flex;
      overflow: hidden;

      span {
        &:first-of-type {
          width: 15px;
          min-width: unset;
          padding: 3px 0;
        }
        width: 100%;
        text-align: left;
      }
    }

    &-number {
      text-align: center !important;
    }
  }

  &__headers {
    display: flex;
    font-weight: bold;

    span {
      font-size: 16px;
      &:first-of-type {
        padding: 3px 7px 3px 0;
        flex-basis: 100%;
        text-align: left;
      }
    }
  }
}
