.order_finished_listing {
  &_table {
    &_status {
      width: 5px;
      height: 40px;
      &.finished {
        background-color: $finishedStatusColor;
      }
      &.cancelled {
        background-color: $cancelledStatusColor;
      }
    }

    &_edit {
      path {
        fill: $infoActiveColor;
      }
    }

    &_cancel {
      path {
        fill: $dangerActiveColor;
      }
    }

    &_active {
      path {
        fill: $successActiveColor;
      }
    }

    &_message {
      path {
        fill: #fab513;
      }
    }

    &_driver_message {
      path {
        fill: $primaryActiveColor;
      }
    }

    &_label {
      path {
        fill: $fontPrimaryColor;
      }
    }
  }
}
