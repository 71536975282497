.horizontal_slider {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 95%;
  margin-right: 10px;
}

.horizontal_slider__input {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 10px;
  background: #fff;
  border-radius: 4px;
  outline: none;
  border: 1px solid #cacaca;
}

.horizontal_slider__input::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 10px;
  background-color: #3699ff;
  cursor: pointer;
  border-radius: 3px;
  border: none;
}

.horizontal_slider__input::-moz-range-thumb {
  width: 20px;
  height: 10px;
  background-color: #3699ff;
  cursor: pointer;
  border-radius: 3px;
  border: none;
}

.horizontal_slider__input::-ms-thumb {
  width: 20px;
  height: 10px;
  background-color: #3699ff;
  cursor: pointer;
  border-radius: 3px;
  border: none;
}
