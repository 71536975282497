$finishedStatusColor: #4ea015;
$stoppedStatusColor: #000000;

.billings_taxi_unbilled_order_listing {
  &__pagination_wrapper {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
  }

  .billings_taxi_unbilled_order_listing_tools {
    display: flex;
    justify-content: space-between;
    position: relative;
  }

  .billings_taxi_unbilled_order_listing_table_status {
    width: 5px;
    height: 40px;

    &.finished {
      background-color: $finishedStatusColor;
    }
    &.cancelled {
      background-color: $stoppedStatusColor;
    }
  }
}
