$badgeBorderRadius: $borderRadius;
$badgeBorderWidth: 1px;

$badgeDefaultBorderColor: #e6e6e6;
$badgeDefaultBackgroundColor: #fff;
$badgeDefaultColor: #000;

$badgePrimaryBorderColor: desaturate($primaryColor, 10%);
$badgePrimaryBackgroundColor: $primaryColor;
$badgePrimaryColor: #fff;

$badgeBrandBorderColor: desaturate($brandColor, 10%);
$badgeBrandBackgroundColor: $brandColor;
$badgeBrandColor: #fff;

$badgeDangerBorderColor: desaturate($dangerColor, 10%);
$badgeDangerBackgroundColor: $dangerColor;
$badgeDangerColor: #fff;

$badgeSuccessBorderColor: desaturate($successColor, 10%);
$badgeSuccessBackgroundColor: $successColor;
$badgeSuccessColor: #fff;

$badgeWarningBorderColor: desaturate($warningColor, 10%);
$badgeWarningBackgroundColor: $warningColor;
$badgeWarningColor: #fff;

$badgeInfoBorderColor: desaturate($infoColor, 10%);
$badgeInfoBackgroundColor: $infoColor;
$badgeInfoColor: #fff;

.badge {
  background-color: $badgeDefaultBackgroundColor;
  border: $badgeBorderWidth solid $badgeDefaultBorderColor;
  border-radius: $badgeBorderRadius;
  padding: 3px 5px;
  font-size: 14px;
  width: fit-content;

  &.primary {
    background-color: $badgePrimaryBackgroundColor;
    border-color: $badgePrimaryBorderColor;
    color: $badgePrimaryColor;
  }

  &.brand {
    background-color: $badgeBrandBackgroundColor;
    border-color: $badgeBrandBorderColor;
    color: $badgeBrandColor;
  }

  &.danger {
    background-color: $badgeDangerBackgroundColor;
    border-color: $badgeDangerBorderColor;
    color: $badgeDangerColor;
  }

  &.success {
    background-color: $badgeSuccessBackgroundColor;
    border-color: $badgeSuccessBorderColor;
    color: $badgeSuccessColor;
  }

  &.warning {
    background-color: $badgeWarningBackgroundColor;
    border-color: $badgeWarningBorderColor;
    color: $badgeWarningColor;
  }

  &.info {
    background-color: $badgeInfoBackgroundColor;
    border-color: $badgeInfoBorderColor;
    color: $badgeInfoColor;
  }
}
