.planning_order_order {
  &_map,
  &_loader {
    height: 250px;
    width: 100%;
    position: relative;
  }

  &_loader {
    display: flex;
    justify-content: center;
  }
}
