$formDisabledOpactity: $mediumOpacity;
$formFieldBorderColor: #dbdbdb;
$formFieldBorderActiveColor: $primaryColor;
$formFieldBorderWidth: 1px;
$formFieldBorderRadius: $borderRadius;
$formFieldErrorColor: $dangerColor;
$formFieldBackgroundColor: #fff;
$formFieldBackgroundActiveColor: #fff;

.form {
  position: relative;
  &.is-loading {
    pointer-events: none;
  }
}

@import "./error/form-error.scss";
@import "./field/form-field.scss";
@import "./select/select.scss";
@import "./date-picker/date-picker/date-picker.scss";
@import "./date-picker/date-range-picker/date-range-picker.scss";
@import "./date-picker/date-time-picker/date-time-picker.scss";
@import "./input/input.scss";
@import "./checkbox/checkbox.scss";
