$breadcrumbColor: #6d6d7c;
$breadcrumbActiveColor: rgb(79, 133, 235);
$breadcrumbSeparatorColor: #b8b8cf;

.breadcrumbs {
  display: flex;
  align-items: center;

  .breadcrumb_item {
    display: flex;
    align-items: center;
    text-decoration: none;
    cursor: pointer;

    .default_active {
      color: $breadcrumbColor;
    }

    &:hover,
    &:focus,
    &.active {
      .breadcrumb_item__text {
        color: $breadcrumbActiveColor;
      }

      .breadcrumb_item__icon {
        path {
          fill: $breadcrumbActiveColor;
        }
      }
    }

    &__text {
      margin-left: 5px;
      line-height: 14px;
      color: $breadcrumbColor;
    }

    &__icon {
      height: 16px;
      path {
        fill: $breadcrumbColor;
      }
    }
  }

  .breadcrumb_separator {
    margin: 0 10px;
    &__icon {
      path {
        fill: $breadcrumbSeparatorColor;
      }
    }
  }

  .breadcrumb_loader {
  }
}
