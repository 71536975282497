@import "./contract-details/contract-details.scss";

.billings {
  .billings_map_wrapper {
    height: 400px;
    width: 100%;
    position: relative;
  }

  .billings_submit_buttons_container {
    display: flex;
    gap: 5px;
  }
}
