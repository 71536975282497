.form_field {
  margin: 16px 0;

  .form_field_label {
    display: flex;
    cursor: default;
    &__content {
      font-size: 1em;
      font-weight: 500;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: block;
    }
  }

  .form_field_required {
    &__wrapper {
      margin-left: 3px;
    }

    &__dot {
      color: $dangerColor;
    }
  }

  &__content {
    margin-top: 3px;
    position: relative;
    width: 100%;
    height: 100%;
  }
}
