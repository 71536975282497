.app_header {
  height: $headerHeight;
  background-color: #fff;
  z-index: $headerZIndex;
  width: 100%;
  position: fixed;
  padding: 0 20px;
  top: 0;
  left: 0;
  border-bottom: 1px solid #e5e5e5;

  .app_header_content {
    display: flex;
    height: 100%;
    padding-left: $asideCollapsedWidth;
    align-items: center;
    justify-content: space-between;
    gap: 12px;

    &.app_aside_expanded {
      padding-left: $asideExpandedWidth;
    }
  }

  .app_header_user {
    position: relative;
  }

  .app_header_user_dropdown_content {
    position: absolute;
    top: calc(100% + 16px);
    right: 0;
    width: 280px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
  }

  .app_header_user_dropdown_content_info_wrapper {
    display: flex;
    gap: 12px;
    padding: 20px 16px;
    border-bottom: 1px solid #e5e5e5;
  }

  .app_header_user_dropdown_content_info {
    display: flex;
    flex-direction: column;
    gap: 2px;

    &__text {
      font-size: 14px;
      font-weight: 500;

      &:not(:first-child) {
        font-size: 13px;
        font-weight: normal;
      }
    }
  }

  .app_header_user_dropdown_content_actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    padding: 16px;

    &__button {
      width: 100%;
    }

    &__link_button {
      width: 100%;
    }

    &__link_button_content {
      width: 100%;
      text-align: center;
    }
  }
}
