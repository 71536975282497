.driver_details_finished_route_plans {
  &__messenger {
    height: 400px;

    .messenger_posts {
      width: 100%;
    }
  }

  &__button_container {
    display: flex;
    gap: 4px;
  }

  .driver_details_finished_route_plans_map_wrapper {
    height: 400px;
    width: 100%;
    position: relative;
  }
}

.driver_details_finished_route_plans_loader_wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}
