.order_active_listing_table {
  &_status {
    width: 5px;
    height: 40px;

    &.candidature {
      background-color: $candidatureStatusColor;
    }

    &.in_progress {
      background-color: $inProgressStatusColor;
    }

    &.pending {
      background-color: $pendingStatusColor;
    }

    &.planned {
      background-color: $plannedStatusColor;
    }
  }

  &_message {
    path {
      fill: #fab513;
    }
  }

  &_driver_message {
    path {
      fill: $primaryActiveColor;
    }
  }

  &_edit {
    path {
      fill: $infoActiveColor;
    }
  }

  &_cancel {
    path {
      fill: $dangerActiveColor;
    }
  }

  &_active {
    path {
      fill: $successActiveColor;
    }
  }

  &_label {
    path {
      fill: $fontPrimaryColor;
    }
  }
}

.order_active_listing_table_route {
  display: block;
  white-space: normal;
}

.order_active_listing_table_driver_mobile_app_user {
  &__icon {
    &.is_user {
      path {
        fill: $successColor;
      }
    }

    &.is_not_user {
      path {
        fill: #a1a1a1;
      }
    }
  }
}

.order_active_listing_order_join {
  position: fixed;
  bottom: 100px;
  right: 100px;
  width: 300px;
  background-color: #fff;
  border-radius: $borderRadius;
  box-shadow: 0px 0px 30px 1px #c9c9c9;
  padding: 10px 12px;

  &__heading {
    font-size: 18px;
  }

  &__content {
    padding-top: 10px;
  }
}

.order_active_listing_time_to_start {
  display: flex;
  gap: 0 4px;
}
