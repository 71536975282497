.planning-order-driver-plan-listing-selected {
  border: 1px solid #ddd;
  border-radius: 4px;

  &__item {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 6px 8px;
    gap: 0 4px;
    border-bottom: 1px solid #ddd;

    &_employment {
      border-left: 1px solid #ddd;
      border-right: 1px solid #ddd;
      padding: 0 10px;
      margin: 0 10px;
    }

    &_name {
      font-size: 16px;
    }

    span {
      font-size: 14px;
    }
  }

  &__action_button {
    width: 100%;
  }

  &__close_button {
    padding: 5px 10px;
    border-radius: 4px;
    background-color: #e9e9e9;

    path {
      fill: #7a7a7a;
    }
  }

  &__map_button {
    padding: 0;

    path {
      fill: #3386cf;
    }
  }

  &__buttons {
    display: flex;
    gap: 0 10px;
    padding-left: 25px;
    margin-left: auto;
    align-items: center;
  }

  &__chart {
    position: relative;
  }

  &__loader {
    display: flex;
    justify-content: center;
    padding: 8px 0 8px 1px;
  }

  &__notification {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px 0;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: rgba(255, 255, 255, 0.7);

    &-success {
      path {
        fill: $successColor;
      }
    }

    &-failure {
      path {
        fill: $dangerColor;
      }
    }
  }
}
