.driver_details_candidature_route_listing {
  &_difference_marker {
    border-radius: 1em;
    width: 1em;
    height: 1em;
    background-color: transparent;

    &.added {
      background-color: $successColor;
    }

    &.removed {
      background-color: $dangerColor;
    }
  }
}
