h1 {
  font-size: 2.5rem;
}
h2 {
  font-size: 2.2rem;
}
h3 {
  font-size: 1.8rem;
}
h4 {
  font-size: 1.4rem;
}
h5 {
  font-size: 1rem;
}
p {
  font-size: 1rem;
}

small {
  font-size: 0.8rem;
}

span {
  font-size: inherit;
}

* {
  font-size: 1rem;
  font-family: $fontFamily;
  color: $fontPrimaryColor;
}
