@import "leaflet/dist/leaflet.css";

.map {
  width: 100%;
  height: 100%;
  min-height: 200px;

  .leaflet-top .leaflet-control {
    margin-top: 35px;
  }

  .map_container {
    width: 100%;
    height: 100%;
    z-index: 0;
    position: relative;
  }

  &__message_box {
    z-index: 1000;
    background: #fff;
    border-radius: 10px;
    padding: 10px 5px;
    position: absolute;
    bottom: 30px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    max-width: 80%;
    width: 350px;
    box-shadow: 0px 16px 9px -5px rgba(66, 68, 90, 0.6);
  }

  &__attribution {
    position: absolute;
    top: 0;
    left: 0;
    background-color: white;
    padding: 2px 3px;
    line-height: 1;
    opacity: 0.85;

    a {
      color: #0078aa;
      text-decoration: none;
      font-size: 1rem;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__button {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
    width: 35px;
    height: 20px;
    padding: 1px;

    &__icon {
      * {
        color: #fff;
      }
    }
  }

  &__slider {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 25px;
    margin-left: auto;
    margin-right: auto;
    max-width: 700px;
    background: rgba(255, 255, 255, 0.9);
    padding: 10px 0px 10px 30px;
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);

    &__timestamps {
      display: flex;
      justify-content: space-between;
      margin-top: 5px;
      font-size: 10px;
      color: #333;
      margin-right: 20px;
      margin-left: -10px;
    }

    &__time {
      white-space: nowrap;
    }

    &__time_left {
      text-align: left;
    }

    &__time_right {
      text-align: right;
    }

    &__time_middle {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      text-align: center;
    }

    &__time_quarter_left {
      position: absolute;
      left: 25%;
      transform: translateX(-50%);
      text-align: center;
    }

    &__time_quarter_right {
      position: absolute;
      left: 75%;
      transform: translateX(-50%);
      text-align: center;
    }
  }
}

.map_marker {
  &.standard {
    path {
      fill: #3386cf;
    }
  }
}

.map_marker_icon_content_wrapper {
  font-size: 13px;
  position: absolute;
  left: 0;
  top: 3px;
  color: #fff;
  display: flex;
  justify-content: center;
  width: 100%;

  path {
    fill: #fff !important;
  }
}

.custom_blue_dot {
  background-color: #0078aa;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid white;
}

.custom_current_gps_dot {
  background-color: #26e9df;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid white;
}
