@import "./user/address/sign-up-passenger-user-address.scss";

.sign_up_passenger {
  min-height: 100vh;
  background: #fff;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;

  &__content {
    display: block;
    position: relative;
    width: 100%;
    max-width: 1600px;
    padding: 40px 20px;

    @include breakpoint(midscreen) {
      padding: 40px;
    }
  }

  &__account {
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: column;
  }

  &__account_content {
    width: 90%;
    max-width: 450px;
    padding: 40px 20px;

    @include breakpoint(midscreen) {
      padding: 40px;
    }
  }

  &__next_button_wrapper {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }

  &__heading {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  &__title {
    text-align: center;
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: 500;
  }

  &__step_title {
    text-align: center;
    text-transform: uppercase;
    font-size: 1.2rem;
    margin-bottom: 15px;
  }

  &__logo {
    width: 100%;
    max-width: 380px;
    margin-bottom: 20px;
  }
}

.sign_up_passenger_wizard_form {
  height: 50px;
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 55px;

  &__line {
    width: 100%;
    height: 3px;
    background-color: #aaaaaa;
  }

  &__options {
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: absolute;
  }

  &__option {
    position: relative;

    &:first-child {
      .sign_up_passenger_wizard_form__option__description {
        left: 0;
        text-align: left;
      }
    }

    &:last-child {
      .sign_up_passenger_wizard_form__option__description {
        left: -110px;
        text-align: right;
      }
    }

    &__description {
      margin-top: 5px;
      position: absolute;
      width: 150px;
      left: calc(-50% - 35px);
      font-size: 14px;
      text-align: center;
      display: none;

      @include breakpoint(midscreen) {
        display: block;
      }
    }
  }

  &__button {
    width: 40px;
    height: 40px;
    color: #fff;
    border-radius: 100%;
    border: 2px solid $primaryColor;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;

    &:not(:disabled):hover {
      border-color: $primaryActiveColor;
      background-color: $primaryActiveColor;
      transition: all $transitionTime;

      .sign_up_passenger_wizard_form__button--icon {
        path {
          transition: all $transitionTime;
          fill: #fff;
        }
      }
    }

    &:disabled {
      border-color: rgba($primaryColor, $lowOpacity);

      .sign_up_passenger_wizard_form__button--icon {
        path {
          opacity: $lowOpacity;
        }
      }
    }

    &.active {
      border-color: $primaryColor;
      background-color: $primaryColor;
      transition: all $transitionTime;

      .sign_up_passenger_wizard_form__button--icon {
        path {
          transition: all $transitionTime;
          fill: #fff;
        }
      }
    }

    &.passed {
      border-color: $successColor;
      background-color: $successColor;

      &:hover {
        border: 2px solid $successActiveColor;
        background-color: $successActiveColor;
        transition: all $transitionTime;
      }
    }

    &--icon {
      path {
        fill: $primaryColor;
      }

      &.passed {
        path {
          fill: #fff;
        }

        opacity: 1;
        position: absolute;
      }
    }
  }
}
