.pagination {
  display: flex;
  &__details {
    display: flex;
    align-items: center;
  }

  &__select {
    margin-left: 10px;
    width: 90px;
  }

  &__page_select_details {
    display: flex;
    align-items: center;
  }
}
