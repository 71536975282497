.cargo_address_listing_sort {
  display: flex;

  &__select {
    width: 200px;
    max-width: 100%;

    .select__option {
      font-size: 14px;
    }

    .select__single-value {
      font-size: 14px;
    }
  }
}

.cargo_address_listing {
  display: flex;
  gap: 20px;
  flex-flow: column;
  .heading {
    margin: 0;
  }
  &__pagination_wrapper {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
  }

  &_table {
    &_route {
      display: block;
      white-space: normal;
    }
  }

  &_tools {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
    justify-content: space-between;
  }

  &_filters {
    display: flex;
    flex-direction: column;
    position: relative;

    &__select {
      width: 200px !important;
      height: 37px;

      .select__option {
        font-size: 14px;
      }

      .select__input {
        font-size: 14px;
      }
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      // flex-direction: column;

      gap: 5px;
    }

    &_badge {
      border: 1px solid #ececec;
      background-color: #fff;
      border-radius: 3px;
      padding: 6px 5px;
      display: flex;
      height: 37px;

      &__text {
        font-size: 14px;
        padding: 0 8px 0 0;
        width: calc(100% - 16px);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &__button {
        padding: 2px;
        height: 16px;
        width: 16px;
      }
    }
  }
}
