.phone_number_input {
  position: relative;
  display: flex;
  align-items: center;
  border-radius: $formFieldBorderRadius;
  border: $formFieldBorderWidth solid $formFieldBorderColor;
  background-color: $formFieldBackgroundColor;
  cursor: default;
  transition: all $transitionTime;

  &:hover {
    border-color: $formFieldBorderActiveColor;
  }

  &.active {
    transition: $transitionTime all;
    background-color: $formFieldBackgroundActiveColor;
    border-color: $formFieldBorderActiveColor;
  }

  &.error {
    border-color: $formFieldErrorColor;
  }

  &.disabled {
    opacity: $formDisabledOpactity;
    pointer-events: none;
  }

  &__input {
    margin: 0 8px 0 10px;
    font-size: 0.9rem;
    border: 0;
    width: 100%;
    padding: 7px 0;
    background: none;

    &::placeholder {
      opacity: $mediumOpacity;
    }
  }

  .phone_number_input_number_info {
    display: flex;
    width: 100%;
    padding-right: 8px;

    &__icon {
      margin-left: 0px;
      margin-top: 10px;
      cursor: pointer;
      opacity: $mediumOpacity;
    }
  }

  .phone_number_input_dialing_code_info {
    display: flex;
    width: 80px;
    padding-left: 8px;

    &__details {
      font-size: 12px;
    }

    &__icon {
      margin-left: 0px;
      margin-top: 3px;
      cursor: pointer;
      opacity: $mediumOpacity;
    }

    &__input {
      font-size: 0.9rem;
      border: 0;
      width: 62px;
      background: none;

      &::placeholder {
        opacity: $mediumOpacity;
      }
    }
  }

  .phone_number_input_dialing_code_list {
    position: absolute;
    top: 42px;
    width: 100%;
    z-index: 100;
    background-color: #fff;
    list-style-type: none;
    border: 1px solid #d8d8d8;
    max-height: 135px;
    overflow: auto;

    .phone_number_input_dialing_code_list_item {
      display: flex;
      align-items: center;
      cursor: pointer;
      border-radius: $formFieldBorderRadius;
      border-bottom: 1px solid $formFieldBorderColor;
      padding: 8px 10px;

      &:hover {
        background-color: #deebff;
      }

      &.focused {
        background-color: #deebff;
      }

      &__code {
        font-weight: 500;
        font-size: 0.9rem;
        width: 20px;
      }

      &__country {
        margin-left: 5px;
        font-size: 0.9rem;
      }

      &__dialing_code {
        width: 40px;
        margin-left: 5px;
        color: #919191;
        font-size: 0.9rem;
      }
    }
  }
}
