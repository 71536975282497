.messenger {
  border: 1px solid #ddd;
  border-radius: $borderRadius;
  display: flex;
  height: 100%;
  width: 100%;

  .messenger_channels {
    border-right: 1px solid #ddd;
    width: 160px;

    &__heading {
      margin-bottom: 10px;
      border-bottom: 1px solid #ddd;
      padding: 10px;
      font-weight: 500;
      height: 50px;
      display: flex;
      align-items: center;
    }

    &__list {
      list-style: none;
    }

    &__item {
      height: 40px;

      display: flex;
      align-items: center;
      margin: 3px 0;
      cursor: pointer;

      &__status {
        width: 5px;
        margin-right: 5px;
        background-color: #8d8d8d;
        height: 100%;

        &.selected {
          background-color: #ffc700;
        }
      }

      &__label {
        display: flex;
        justify-content: space-between;
        margin-right: 4px;
        width: calc(100% - 14px);
        &__label {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-right: 2px;
        }
        &__count {
          &__badge {
            min-width: 21px;
            text-align: center;
          }
        }
      }
    }
  }

  .messenger_posts {
    width: calc(100% - 160px);
    position: relative;
    display: flex;
    flex-flow: column;

    &__heading {
      height: 50px;
      padding: 10px;
      margin-bottom: 10px;
      border-bottom: 2px solid #f7f7f7;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &__text {
        width: calc(100% - 50px);
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    &__list {
      display: flex;
      flex-direction: column;
      flex: 1;
      overflow: auto;
      position: relative;
      list-style: none;
      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #62627c;
      }
    }

    &__loader_wrapper,
    &__error_wrapper,
    &__no_messages_wrapper {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
    }

    &__error_text,
    &__no_messages_text {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    &__error_content {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      white-space: break-spaces;
      padding: 10px;
      text-align: center;
    }

    &__item {
      padding: 3px;
      margin: 5px 10px;
      background-color: #ececec;
      border: 1px solid #ddd;
      width: 80%;
      max-width: 80%;
      border-radius: 3px;
      width: 80%;

      &.own {
        align-self: flex-end;
        background-color: #ffffff;
      }

      &.raily {
        align-self: flex-end;
      }

      &.accepted {
        align-self: flex-end;
        background-color: #4cbb17;
      }

      &.rejected {
        align-self: flex-end;
        background-color: red;
      }

      &.reopened {
        align-self: flex-end;
        background-color: yellow;
      }

      &.reopen_request {
        align-self: flex-end;
        background-color: yellowgreen;
      }

      &.billed {
        align-self: flex-end;
        background-color: #0096ff;
      }

      &.updated {
        align-self: flex-end;
        background-color: #4ef8f8;
      }

      &.user_entry {
        align-self: flex-end;
        background-color: white;
      }

      &__details_wrapper {
        display: flex;
        flex-direction: column;
        margin-bottom: 4px;
      }

      &__details {
        font-size: 12px;
        font-weight: 400;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      &__details_company {
        font-size: 11px;
      }

      &__content {
        font-size: 14px;
        white-space: break-spaces;
        word-wrap: break-word;
      }
    }

    &__sending {
      display: flex;
      margin: 10px 10px 6px;

      &__input {
        margin-right: 5px;
        width: 100%;
      }

      &__icon {
        path {
          fill: #fff;
        }
      }
    }
  }
}
