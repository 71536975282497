.driver_add_user_add_address {
  .driver_add_user_add_address_table_row {
    &.address {
      display: flex;
      align-items: center;

      .text {
        display: block;
      }

      .icon {
        margin-right: 5px;
      }
    }
  }

  &__add_button_wrapper {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }

  &__map_wrapper {
    width: 100%;
    position: relative;
    height: 500px;
  }

  &__map_marker_signature_icon {
    margin-top: 2px;
    margin-left: -1px;
    path {
      fill: #fff !important;
    }
  }
}

.driver_add_user_add_address {
  &__message_box {
    display: flex;
    max-height: 40px;
  }

  .driver_add_user_add_address_map_form_field {
    width: 100%;
    height: 100%;
    min-height: 300px;
    position: relative;
  }
}

.driver_add_company_heading_text {
  font-size: 1.2rem;
  font-weight: 500;
  margin: 20px 0 10px 0;
}

.driver_add_buttons {
  display: flex;
  gap: 5px;
  align-items: flex-end;
  margin-right: 0;
}
