.map-address-selector {
  &-message-box {
    display: flex;
    max-height: 40px;

    &-text {
      font-size: 12px;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      padding: 0 2px;
    }
  }
}