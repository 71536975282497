.contract_details {
  .contract_details_item {
    display: flex;
    gap: 0 5px;

    :nth-child(2) {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      display: block;
    }
  }
}
