.table {
  width: 100%;
  position: relative;
  display: block;

  &.loading {
    display: table;
  }

  @include breakpoint(midscreen) {
    display: table;
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;
  }

  &__head {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;

    @include breakpoint(midscreen) {
      border: unset;
      clip: unset;
      height: unset;
      margin: unset;
      overflow: unset;
      padding: unset;
      position: unset;
      width: unset;
    }
  }

  &__body {
    display: block;

    &.loading {
      display: table-row-group;
    }

    @include breakpoint(midscreen) {
      display: table-row-group;
    }
  }

  &__tr {
    border-radius: $borderRadius;
    display: block;
    margin-bottom: 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.4);

    @include breakpoint(midscreen) {
      display: table-row;
      background-color: transparent;
      border-radius: unset;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    &.clickable {
      cursor: pointer;
    }

    &.hidden {
      display: none;
    }
  }

  &__th,
  &__td {
    padding: 10px 8px;
    text-align: left;
    font-weight: 300;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
  }

  &__td {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    font-size: 0.8rem;
    position: relative;

    &:before {
      content: attr(data-label);
      float: left;
      font-weight: bold;
      text-transform: uppercase;
      margin-right: 10px;
      position: absolute;
      left: 12px;
    }

    &:last-child {
      border-bottom: 0;
    }

    @include breakpoint(midscreen) {
      border-bottom: unset;
      display: table-cell;
      vertical-align: middle;
      font-size: 0.9rem;
      text-align: unset;

      &:before {
        content: unset;
        float: unset;
        font-weight: unset;
        text-transform: unset;
      }

      &:last-child {
        border-bottom: 0;
      }
    }

    &__content {
      font-size: 0.8rem;
      max-width: 100%;
      margin-top: 25px;
      width: 100%;
      height: 100%;
      position: relative;

      @include breakpoint(midscreen) {
        margin-top: unset;
        font-size: 0.9rem;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      & * {
        font-size: 0.9rem;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }

  &__th {
    vertical-align: initial;

    &__content {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__title {
      text-align: left;
      font-weight: 300;
      text-overflow: ellipsis;
      overflow: hidden;

      @include breakpoint(midscreen) {
        font-size: 0.9rem;
        font-weight: 600;
      }
    }
  }

  &__loader_wrapper,
  &__no_data_wrapper {
    padding: 10px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  &__overlay_loader_wrapper {
    position: absolute;
    background-color: #fff;
    opacity: $mediumOpacity;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__overlay_loader {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  &__error_wrapper {
    color: $dangerColor;
    padding: 10px;
    text-align: center;
  }
}

.table_button {
  padding: 2px;
  width: 28px;
  height: 28px;
  margin-left: 3px;
  text-overflow: unset;
  position: relative;

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100% !important;
    height: 100%;
  }

  &__icon {
    path {
      fill: #fff;
    }
  }
}

.table_nav_link {
  font-size: 0.9rem;
  color: $primaryColor;
  text-decoration: none;
  transition: all $transitionTime;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;

  &:hover {
    color: $primaryActiveColor;
    text-decoration: underline;
  }
}
