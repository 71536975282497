.delegation_details {
  $selectedPassengerTextColor: $primaryColor;
  $selectedPassengerDefaultPlaceTextColor: rgba($primaryColor, 1);

  .map_marker {
    &.default {
      path {
        fill: $selectedPassengerDefaultPlaceTextColor;
      }
    }
  }

  &_map_wrapper {
    height: 400px;
    width: 100%;
    position: relative;
  }

  &_passengers {
    margin-top: 10px;

    &_item {
      display: flex;
      align-items: center;
      margin: 7px 0;
      position: relative;
      cursor: pointer;

      & > * {
        font-size: 14px;
      }

      &__icon {
        margin-right: 5px;
        margin-top: -2px;
      }

      &__name {
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }

  &_routes {
    margin-top: 10px;

    .delegation_details_route {
      list-style-type: none;
      display: flex;
      align-items: center;
      margin: 2px 0;
      position: relative;

      & * {
        font-size: 14px;
        text-overflow: ellipsis;
        overflow: hidden;
        display: block;
      }

      &__icon {
        margin-right: 5px;
      }

      &__connection_line {
        left: 20.5px !important;
      }

      &__place {
        display: flex;
        align-items: center;

        &_index {
          width: 10px;
          text-align: center;
        }

        &_dot {
          width: 6px;
          height: 6px;
          background-color: #000;
          border-radius: 100%;
          z-index: 2;
          margin: 0 7px;
        }

        &_manual {
          width: calc(100% - 120px);
        }

        &_auto {
          width: calc(100% - 250px);
        }
      }

      &__mileage_id {
        width: 120px;
        padding: 3px 7px;
      }

      &__time {
        width: 130px;
        padding: 3px 7px;
      }
    }
  }
}
