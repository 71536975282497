.mileage_dictionary_listing {
  &_table {
    &_edit {
      path {
        fill: $infoActiveColor;
      }
    }

    &_cancel {
      path {
        fill: $dangerActiveColor;
      }
    }

    &_active {
      path {
        fill: $successActiveColor;
      }
    }

    &_message {
      path {
        fill: #fab513;
      }
    }

    &_label {
      path {
        fill: $fontPrimaryColor;
      }
    }
  }
}
