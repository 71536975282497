$buttonBorderRadius: $borderRadius;

$buttonPrimaryBackgroundColor: $primaryColor;
$buttonPrimaryBackgroundActiveColor: $primaryActiveColor;
$buttonPrimaryColor: #fff;

$buttonBrandBackgroundColor: $brandColor;
$buttonBrandBackgroundActiveColor: $brandActiveColor;
$buttonBrandColor: #fff;

$buttonDangerBackgroundColor: $dangerColor;
$buttonDangerBackgroundActiveColor: $dangerActiveColor;
$buttonDangerColor: #fff;

$buttonSuccessBackgroundColor: $successColor;
$buttonSuccessBackgroundActiveColor: $successActiveColor;
$buttonSuccessColor: #fff;

$buttonWarningBackgroundColor: $warningColor;
$buttonWarningBackgroundActiveColor: $warningActiveColor;
$buttonWarningColor: #fff;

$buttonInfoBackgroundColor: $infoColor;
$buttonInfoBackgroundActiveColor: $infoActiveColor;
$buttonInfoColor: #fff;

.button {
  padding: 10px 12px;
  text-decoration: none;
  border: none;
  background: none;
  display: block;
  font-size: 0.8rem;
  border-radius: $buttonBorderRadius;
  position: relative;
  background: none;
  transition: background-color $transitionTime;
  width: fit-content;

  &.primary {
    background-color: $buttonPrimaryBackgroundColor;
    color: $buttonPrimaryColor;

    &:hover:not(.disabled),
    &:focus:not(.disabled) {
      background-color: $buttonPrimaryBackgroundActiveColor;
    }

    .button_content {
      color: $buttonPrimaryColor;
    }
  }

  &.brand {
    background-color: $buttonBrandBackgroundColor;
    color: $buttonBrandColor;

    &:hover:not(.disabled),
    &:focus:not(.disabled) {
      background-color: $buttonBrandBackgroundActiveColor;
    }

    .button_content {
      color: $buttonBrandColor;
    }
  }

  &.danger {
    background-color: $buttonDangerBackgroundColor;
    color: $buttonDangerColor;

    &:hover:not(.disabled),
    &:focus:not(.disabled) {
      background-color: $buttonDangerBackgroundActiveColor;
    }

    .button_content {
      color: $buttonDangerColor;
    }
  }

  &.success {
    background-color: $buttonSuccessBackgroundColor;
    color: $buttonSuccessColor;

    &:hover:not(.disabled),
    &:focus:not(.disabled) {
      background-color: $buttonSuccessBackgroundActiveColor;
    }

    .button_content {
      color: $buttonSuccessColor;
    }
  }

  &.warning {
    background-color: $buttonWarningBackgroundColor;
    color: $buttonWarningColor;

    &:hover:not(.disabled),
    &:focus:not(.disabled) {
      background-color: $buttonWarningBackgroundActiveColor;
    }

    .button_content {
      color: $buttonWarningColor;
    }
  }

  &.info {
    background-color: $buttonInfoBackgroundColor;
    color: $buttonInfoColor;

    &:hover:not(.disabled),
    &:focus:not(.disabled) {
      background-color: $buttonInfoBackgroundActiveColor;
    }

    .button_content {
      color: $buttonInfoColor;
    }
  }

  &.disabled {
    cursor: default;
    opacity: 0.7;
  }

  &.loading {
    pointer-events: none;
  }

  .button_content {
    position: relative;
    width: max-content;
    font-size: 0.8rem;

    &.loading {
      visibility: hidden;
    }
  }

  .button_loader {
    position: absolute;
    display: block;
    width: 20px;
    height: 20px;
    top: calc(50% - 11px);
    left: calc(50% - 10px);
    border: 2px solid #fff;
    border-bottom-color: transparent;
    border-radius: 50%;
    animation: 0.6s button_loader linear infinite;
  }

  @keyframes button_loader {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
