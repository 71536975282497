/*
 * Global
 */
$fontFamily: "Poppins", sans-serif;
$transitionTime: 0.25s;
$headerHeight: 50px;
$pagePadding: calc(#{$headerHeight} + 16px) 20px 10px 20px;

$pageBackgroundColor: #fcfcfc;
$fontPrimaryColor: #303657;

$primaryColor: #3699ff;
$primaryActiveColor: #228af1;
$brandColor: #585858;
$brandActiveColor: #353535;
$dangerColor: #df4f5d;
$dangerActiveColor: #f13447;
$successColor: #53be50;
$successActiveColor: #23c51e;
$warningColor: #dfb436;
$warningActiveColor: #d1a111;
$infoColor: #26c0d3;
$infoActiveColor: #17b2c7;
$appLogoColor: #33b8eb;
$marketplaceLogoColor: #fab513;

$asideBackgroundColor: #1e1e2d;
$asideCollapsedWidth: 52px;
$asideExpandedWidth: 265px;

/*
 * Forms
 */
$formFieldBorderRadius: 0px;
$formFieldBorderWidth: 1px;
$formFieldBackgroundColor: #4f5158;
$formFieldBorderColor: #dbdbdb;
$formFieldBackgroundActiveColor: #595959;
$formFieldBorderActiveColor: #595959;
$formFieldActiveBorderBottomColor: $primaryColor;
$formFieldErrorColor: $dangerColor;
$formFieldLabelColor: #d3d3d5;
$formFieldErrorMessageFontSize: 12px;
$formFieldFontColor: $fontPrimaryColor;
$formFieldDropdownBackgroundColor: $primaryColor;
$formFieldDropdownListBorderRadius: $formFieldBorderRadius;

$formFieldCheckboxBorderActiveColor: $primaryColor;
$formFieldCheckboxActiveBackgroundColor: $primaryColor;
$formFieldCheckboxCheckmarkColor: #fff;

$borderRadius: 4px;
$lowOpacity: 0.2;
$mediumOpacity: 0.5;
$highOpacity: 0.8;

/*
 * Breakpoints
 */
$xsmallscreen: 400px !default;
$smallscreen: 480px !default;
$midscreen: 768px !default;
$largescreen: 992px !default;
$xlargescreen: 1200px !default;
$xxlargescreen: 1600px !default;

:export {
  primaryColor: $primaryColor;
  primaryActiveColor: $primaryActiveColor;
  brandColor: $brandColor;
  brandActiveColor: $brandActiveColor;
  dangerColor: $dangerColor;
  dangerActiveColor: $dangerActiveColor;
  successColor: $successColor;
  successActiveColor: $successActiveColor;
  warningColor: $warningColor;
  warningActiveColor: $warningActiveColor;
  infoColor: $infoColor;
  infoActiveColor: $infoActiveColor;
}

/*
 * Statuses
 */

$candidatureStatusColor: #c415e0;
$inProgressStatusColor: #66d01d;
$pendingStatusColor: #d9d9d9;
$plannedStatusColor: #1dbbed;
$cancelledStatusColor: #000;
$finishedStatusColor: #4ea015;
$createdStatusColor: #26c0d3;
$acceptedStatusColor: #53be50;
$rejectedStatusColor: #df4f5d;
$reopenedStatusColor: #7236ffcc;
$reopenRequestStatusColor: #d1a111;
$currentPlanStatusColor: #008bf7;
$potentialPlanStatusColor: #7ab6ff;
$orderStatusColor: #00c9b7;
$proposesPotentialStatusColor: #87e7a4;
$delegationCreatedStatusColor: #0c55ba;
$undownloadedStatusColor: #c4c4c4;
$downloadedStatusColor: #39da39;
$deletedStatusColor: #ff0000;
