@import "styles/variables.module.scss";
@import "styles/reset.scss";
@import "styles/typography.scss";
@import "styles/z-index.scss";
@import "styles/mixins/media-query.scss";
@import "styles/grid.min.css";
@import "styles/helpers";

/*
 * Common
 */
@import "common/components/badge/badge.scss";
@import "common/components/button/button.scss";
@import "common/components/breadcrumbs/breadcrumbs.scss";
@import "common/components/card/card.scss";
@import "common/components/error/error.scss";
@import "common/components/form/form.scss";
@import "common/components/heading/heading.scss";
@import "common/components/listing/filter/badge/listing-filter-badge.scss";
@import "common/components/listing/pagination/listing-pagination.scss";
@import "common/components/listing/filter/badge-list/listing-filter-badge-list.scss";
@import "common/components/listing/filter/select/listing-filter-select.scss";
@import "common/components/listing/filter/sort/select/listing-sort-select.scss";
@import "common/components/loader/loader.scss";
@import "common/components/map/map.scss";
@import "common/components/map-address-selector/map-address-selector.scss";
@import "common/components/modal/modal.scss";
@import "common/components/notice-box/notice-box.scss";
@import "common/components/page/loader/page-loader.scss";
@import "common/components/pagination/pagination.scss";
@import "common/components/table/table.scss";
@import "common/components/listing/filter/listing-filter.scss";
@import "common/components/tabs/tabs.scss";
@import "common/utils/notification/notification.scss";
@import "common/components/chart/chart.scss";
@import "common/components/app-logo/app-logo.scss";
@import "common/components//status-legend/status-legend.scss";
@import "common/components/messenger/messenger.scss";
@import "common/components/tabs/tabs.scss";
@import "common/components/horizontal-slider/horizontal-slider.scss";

/*
 * Modules
 */
@import "modules/auth/auth.scss";
@import "modules/app-header/app-header.scss";
@import "modules/app-aside/app-aside.scss";
@import "modules/order/active-listing/order-active-listing.scss";
@import "modules/order/details/order-details.scss";
@import "modules/route/details/route-details.scss";
@import "modules/order/abandoned-listing/order-abandoned-listing.scss";
@import "modules/cargo/address/add/cargo-address-add.scss";
@import "modules/cargo/address/edit/cargo-address-edit.scss";
@import "modules/cargo/address/listing/cargo-address-listing.scss";
@import "modules/cargo/taxi-contract/listing/cargo-taxi-contract-listing.scss";
@import "modules/order/add-new/order-add.scss";
@import "modules/order/route-edit/order-route-edit.scss";
@import "modules/order/common/routes/waypoint-address-add-form/routes-waypoint-address-add-form.scss";
@import "modules/order/details/change-taxi/order-details-change-taxi.scss";
@import "modules/order/finished-listing/order-finished-listing.scss";
@import "modules/order/join/order-join.scss";
@import "modules/taxi/cargo-contract/listing/taxi-cargo-contract-listing.scss";
@import "modules/user/passenger/add/passenger-add.scss";
@import "modules/user/passenger/edit/passenger-edit.scss";
@import "modules/user/driver/add/driver-add.scss";
@import "modules/user/driver/edit/driver-edit.scss";
@import "modules/user/driver/common/address-form/driver-address-form.scss";
@import "modules/user/driver/details/active-route-plans/driver-details-active-route-plans.scss";
@import "modules/user/driver/details/candidature/management/route-listing/driver-details-candidature-management-route-listing.scss";
@import "modules/user/driver/contract/edit/driver-contract-edit.scss";
@import "modules/user/driver/contract/listing/driver-contract-listing.scss";
@import "modules/billings/billings.scss";
@import "modules/billings/cargo/order-listing/billings-cargo-order-listing.scss";
@import "modules/billings/taxi/order-listing/billings-taxi-order-listing.scss";
@import "modules/billings/taxi/route-listing/billings-taxi-route-listing.scss";
@import "modules/billings/taxi/transferred-order-listing/billings-taxi-transferred-order-listing.scss";
@import "modules/billings/taxi/unbilled-order-listing/billings-taxi-unbilled-order-listing.scss";
@import "modules/billings/taxi/unbilled-private-order-listing/billings-taxi-unbilled-private-order-listing.scss";
@import "modules/billings/taxi/unbilled-route-listing/billings-taxi-unbilled-route-listing.scss";
@import "modules/billings/taxi/unbilled-transferred-order-listing//billings-taxi-unbilled-transferred-order-listing.scss";
@import "modules/route/active-listing/route-active-listing.scss";
@import "modules/route/finished-listing/route-finished-listing.scss";
@import "modules/order/details/modals/modals.scss";
@import "modules/planning/order/driver-plan-listing/planning-order-driver-plan-listing.scss";
@import "modules/planning/order/driver-plan-listing-selected/planning-order-driver-plan-listing-selected.scss";
@import "modules/planning/order/order-details/planning-order-order-details.scss";
@import "modules/planning/order/order-map/planning-order-order-map.scss";
@import "modules/mileage/listing/mileage-listing.component.scss";
@import "modules/mileage/common/routes/address-add-modal/address-add-modal.scss";
@import "modules/mileage/add/mileage-add.scss";
@import "modules/delegation/listing/delegation-listing.component.scss";
@import "modules/mileage/dictionary/listing/mileage-dictionary-listing.component.scss";
@import "modules/mileage/details/mileage-details.scss";
@import "modules/mileage/update/mileage-update.scss";
@import "modules/delegation/add/delegation-add.scss";
@import "modules/delegation/details/delegation-details.scss";
@import "modules/delegation/common/routes/address-add-modal/delegation-address-add-modal.scss";
@import "modules/billings/cargo-taxi/common/contributions/discount/table/billings-cargo-taxi-contributions-discount-table.scss";
@import "modules/billings/taxi-driver/common/contributions/bonus/table/billings-taxi-driver-contributions-bonus-table.scss";
@import "modules/billings/taxi-driver/common/contributions/contract-correction/table/billings-taxi-driver-contributions-contract-correction-table.scss";
@import "modules/billings/taxi-driver/common/contributions/penalty/table/billings-taxi-driver-contributions-penalty-table.scss";
@import "modules/order-optimizer/listing/order-optimizer-listing.scss";
@import "modules/user/driver/details/finished-route-plans/driver-details-finished-route-plans.scss";
@import "modules/order-optimizer/details/order-optimizer-details.scss";
@import "modules/cargo/cargo-taxi-contract/listing/cargo-cargo-taxi-contract-listing.component.scss";
@import "modules/taxi/listing/taxi-listing.scss";

/*
 * Fonts
 */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600&display=swap");

* {
  outline: none;
}

.app {
  display: flex;
  background-color: $pageBackgroundColor;

  &.loading {
    align-items: center;
    height: 100vh;
  }

  .page_content {
    padding: $pagePadding;
    position: relative;
    min-height: 100vh;
    left: $asideCollapsedWidth;
    width: calc(100% - $asideCollapsedWidth);
    transition: all $transitionTime;

    &.app_aside_pinned {
      left: $asideExpandedWidth;
      width: calc(100% - $asideExpandedWidth);
    }
  }
}
