@import "./sign-up/driver/sign-up-driver.scss";
@import "./sign-up/cargo-officer/sign-up-cargo-officer.scss";
@import "./sign-up/taxi-officer/sign-up-taxi-officer.scss";
@import "./sign-up/dealer/sign-up-dealer.scss";
@import "./sign-up/dispatcher/sign-up-dispatcher.scss";
@import "./sign-up/passenger/sign-up-passenger.scss";

.auth {
  min-height: 100vh;
  background: #fff;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &__content {
    width: 90%;
    max-width: 450px;
    padding: 40px 20px;

    @include breakpoint(midscreen) {
      padding: 40px;
    }
  }

  &__title {
    text-align: center;
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: 500;
  }

  &__error_message {
    margin-top: 10px;
    text-align: center;
  }

  &__logo {
    margin-bottom: 50px;
  }

  &__submit_button {
    margin-top: 30px;
    width: 100%;
  }

  &__language {
    position: absolute;
    top: 6px;
    right: 82px;
  }

  &__copyright {
    display: flex;
    align-items: center;
    font-size: 12px;
    gap: 0 4px;

    .button,
    .button_content {
      padding: 0;
      font-size: 12px;
    }
  }
}
