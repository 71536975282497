.route_details {
  $selectedPassengerTextColor: $primaryColor;
  $selectedPassengerOnboardPlaceTextColor: #329632;
  $selectedPassengerOutboardPlaceTextColor: #bb4324;
  $selectedPassengerWeatheredPlaceTextColor: rgba($primaryColor, 0.5);

  .route_details_loader_wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
  }

  .route_details_error {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &__heading {
      font-weight: 500;
    }

    &__retry_button {
      margin-top: 20px;
    }
  }

  .route_details_map_wrapper {
    height: 400px;
    width: 100%;
    position: relative;
  }

  .route_details_tools {
    width: 50px;
    top: 0;
    right: 0;
    position: absolute;
    background-color: #f3f3f3;
    width: fit-content;
    max-width: 100%;
    display: flex;
    align-items: center;
    border-bottom-left-radius: 5px;

    .route_details_tools_button {
      padding: 8px 8px 6px 8px;
      width: 32px;
      height: 32px;
      margin: 5px;
      border-radius: 100%;

      &__icon {
        path {
          fill: #fff;
        }
      }

      &.active {
        background-color: $successColor;
      }
    }
  }

  .map_marker {
    &.onboarding {
      path {
        fill: $selectedPassengerOnboardPlaceTextColor !important;
      }
      z-index: 1000;
    }

    &.outboarding {
      path {
        fill: $selectedPassengerOutboardPlaceTextColor;
      }
      z-index: 1000;
    }

    &.weathered {
      path {
        fill: $selectedPassengerWeatheredPlaceTextColor;
      }
    }
  }

  .route_details_not_completed_route_notification {
    margin: 10px 0;
    display: block;
    white-space: normal;
    word-wrap: break-word;
    max-width: 100%;
  }

  .route_details_cargo_external_id_wrapper {
    max-width: 100%;
    margin-bottom: 10px;
    white-space: normal;
    word-wrap: break-word;
  }

  .route_details_passengers {
    margin-top: 10px;

    .route_details_passengers_item {
      display: flex;
      align-items: center;
      margin: 7px 0;
      position: relative;
      cursor: pointer;

      & > * {
        font-size: 14px;
      }

      &__icon {
        margin-right: 5px;
        margin-top: -2px;
      }

      &__name {
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      &.selected {
        .route_details_passengers_item__icon {
          path {
            fill: $selectedPassengerTextColor;
          }
        }

        .route_details_passengers_item__name {
          color: $selectedPassengerTextColor;
        }
      }
    }
  }

  .route_details_drivers {
    margin-top: 10px;

    .route_details_drivers_list_item_button {
      margin: 0;
      padding: 0;

      &__content {
        display: flex;
        align-items: center;
        margin: 7px 0;
        position: relative;

        &.candidate {
          .route_details_drivers_list_item_button__content__icon {
            path {
              fill: #c415e0;
            }
          }

          .route_details_drivers_list_item_button__content__name {
            color: #c415e0;
          }
        }

        & > * {
          font-size: 14px;
        }

        &__icon {
          margin-right: 5px;
          margin-top: -2px;
        }

        &__name {
          width: 100%;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        &:hover,
        &:focus {
          .route_details_drivers_list_item_button__content__icon {
            path {
              fill: $primaryColor;
            }
          }

          .route_details_drivers_list_item_button__content__name {
            color: $primaryColor;
          }
        }
      }
    }

    .route_details_drivers_list_item {
      margin: 0;
      padding: 0;

      &__content {
        display: flex;
        align-items: center;
        margin: 7px 0;
        position: relative;

        & > * {
          font-size: 14px;
        }

        &.candidate {
          .route_details_drivers_list_item__content__icon {
            path {
              fill: #c415e0;
            }
          }

          .route_details_drivers_list_item__content__name {
            color: #c415e0;
          }
        }

        &__icon {
          margin-right: 5px;
          margin-top: -2px;
        }

        &__name {
          width: 100%;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }
  }

  .route_details_cargo_external_id_wrapper {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .route_details_routes {
    margin-top: 10px;

    .route_details_route {
      list-style-type: none;
      display: flex;
      align-items: center;
      margin: 2px 0;
      position: relative;

      &.onboard_highlight * {
        color: $selectedPassengerOnboardPlaceTextColor;
      }

      &.outboard_highlight * {
        color: $selectedPassengerOutboardPlaceTextColor;
      }

      &.faded {
        opacity: $mediumOpacity;
      }

      & * {
        font-size: 14px;
        text-overflow: ellipsis;
        overflow: hidden;
        display: block;
      }

      .dot {
        width: 6px;
        height: 6px;
        background-color: #000;
        border-radius: 100%;
        z-index: 2;
        margin: 0 7px;
      }

      &__icon {
        margin-right: 5px;
      }

      &__connection_line {
        left: 20.5px !important;
      }

      &__place {
        width: calc(100% - 65px - 65px - 65px - 70px - 65px);
        display: flex;
        align-items: center;

        &__index {
          width: 10px;
          text-align: center;
        }

        &__name {
          width: calc(100% - 20px);

          &__link {
            color: inherit;
            text-decoration: none;

            &:hover {
              color: revert;
            }
          }
        }
      }

      &__ordered_date {
        width: 65px;
        padding: 3px 7px;
      }

      &__estimated_date {
        width: 65px;
        padding: 3px 7px;
      }

      &__halting_date {
        width: 65px;
        padding: 3px 7px;
      }

      &__distance {
        width: 70px;
        padding: 3px 7px;
      }

      &__completion_date {
        width: 65px;
        padding: 3px 7px;
      }
    }
  }
}
