.listing_filter_badge_list {
  display: flex;
  flex-flow: row wrap;
  gap: 5px;
  margin: 5px 0;

  &__clear_button {
    padding: 2px;
  }
}
