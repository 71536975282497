.order_join {
  .order_join_details {
    &__map_wrapper {
      height: 300px;
    }
  }

  .order_join_select_order_button_wrapper {
    display: flex;
    gap: 5px;
    margin-bottom: 5px;
    flex-wrap: wrap;
  }

  .order_join_passengers {
    margin: 5px 0;
    display: flex;
    // gap: 15px;
    flex-wrap: wrap;

    .order_join_passengers_item {
      display: flex;
      align-items: center;
      margin: 2px 10px 2px 0;
      position: relative;

      & > * {
        font-size: 14px;
      }

      &__icon {
        margin-right: 5px;
        margin-top: -2px;
      }

      &__name {
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }

  .order_join_drivers {
    margin-top: 10px;

    .order_join_drivers_list {
      display: flex;
      gap: 15px;
      flex-wrap: wrap;
    }

    .order_join_drivers_list_item {
      margin: 0;
      padding: 0;
      display: flex;

      &__content {
        display: flex;
        align-items: center;
        margin: 7px 0;
        position: relative;

        & > * {
          font-size: 14px;
        }

        &__icon {
          margin-right: 5px;
        }

        &__name {
          width: 100%;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }
  }

  .order_join_routes {
    margin-top: 10px;

    .order_join_route {
      list-style-type: none;
      display: flex;
      align-items: center;
      margin: 2px 0;
      position: relative;

      &.faded {
        opacity: $mediumOpacity;
      }

      & * {
        font-size: 14px;
        text-overflow: ellipsis;
        overflow: hidden;
        display: block;
      }

      &__icon {
        margin-right: 5px;
      }

      .place {
        width: calc(100% - 70px - 70px - 70px - 70px);
        display: flex;
        align-items: center;

        .index {
          width: 15px;
          text-align: center;
        }

        .dot {
          width: 6px;
          height: 6px;
          background-color: #000;
          border-radius: 100%;
          z-index: 2;
          margin: 0 7px;
        }

        &__name {
          width: calc(100% - 40px);
        }
      }

      .ordered_date {
        width: 70px;
        padding: 3px 7px;
        white-space: nowrap;
      }

      .estimated_date {
        width: 70px;
        padding: 3px 7px;
        white-space: nowrap;
      }

      .halting_date {
        width: 70px;
        padding: 3px 7px;
      }

      .distance {
        width: 70px;
        padding: 3px 7px;
      }
    }
  }
}
