.tabs {
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 0px 30px -10px rgb(201, 201, 201);
  border-radius: $borderRadius;
  padding: 12px 20px;
}

.tabs_header {
  display: flex;
  gap: 20px;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  overflow-y: none;
  overflow-x: auto;
}

.tabs_header_tab {
  position: relative;
  display: flex;
  flex: 1 0 0;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding-bottom: 8px;
  cursor: pointer;

  &__label {
    font-size: 18px;
  }

  &__counter {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: 22px;
    background-color: $primaryColor;
    color: #fff;
    font-size: 14px;
    border-radius: 50%;
  }

  &__active_underline {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 3px;
    background-color: $primaryColor;
  }
}

.tabs_content {
  width: 100%;
  height: 100%;
  margin-top: 12px;
}
