$modalDesktopBorderRadius: 12px;

.modal {
  background: #fff;
  position: relative;
  transition: all $transitionTime;
  height: 100vh;
  width: 100%;
  @include breakpoint(smallscreen) {
    max-width: 80%;
    width: 960px;
    border-radius: $modalDesktopBorderRadius;
    height: auto;
  }

  &__wrapper {
    max-height: 90vh;
    @include breakpoint(smallscreen) {
      margin: 20px;
    }
  }

  .modal__close {
    display: flex;
  }

  &__background {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(#000, 0.5);
    z-index: $modalZIndex;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: all $transitionTime;
    display: flex;
    align-items: center;
    justify-content: center;
    &.is-open {
      opacity: 1;
      pointer-events: auto;
      visibility: visible;
    }
  }

  &__header {
    display: flex;
    position: relative;
    padding: 10px 7px 5px 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  &__title {
    color: $fontPrimaryColor;
    width: calc(100% - 42px);
    text-overflow: ellipsis;
    overflow: hidden;
    padding-top: 2px;
  }

  &__close {
    &__button {
      margin-left: 5px;
      background-color: #e9e9e9;

      &:hover,
      &focus {
        background-color: $dangerColor;

        .modal__close__icon {
          path {
            fill: #fff;
          }
        }
      }
    }

    &__icon {
      path {
        fill: rgb(122, 122, 122);
      }
    }
  }

  &__container {
    padding: 5px 10px 15px 10px;
    @include breakpoint(smallscreen) {
      padding: 2px 10px 25px 10px;
    }
  }

  &__content {
    position: relative;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: calc(100vh - 140px);
    padding: 10px 0;
    @include breakpoint(smallscreen) {
      max-height: calc(100vh - 250px);
    }

    &::-webkit-scrollbar {
      width: 20px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #343842;
      border-radius: 20px;
      border: 6px solid transparent;
      background-clip: content-box;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #40434b;
    }
  }

  &__cta {
    margin-top: 15px;
    display: flex;

    &__item {
      &:not(:first-child) {
        margin-left: 10px;
      }
    }
  }

  &__options-wrapper {
    display: flex;
    justify-content: center;
  }

  .modal_overlay {
    background-color: rgba(#fff, 0.9);
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: $modalDesktopBorderRadius;
  }
}

.modal_close_confirmation {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;

  &__title {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }

  &__buttons_wrapper {
    margin-top: 50px;
    display: flex;
  }

  &__button {
    &:not(:first-child) {
      margin-left: 10px;
    }
  }
}
