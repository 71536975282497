.order_optimizer_listing_filter {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__button {
    margin-top: 26px;
  }

  &__input_wrapper {
    display: flex;
    align-items: center;

    &__button {
      padding: 9px 12px;
      margin-top: 27px;

      &__icon {
        * {
          color: #fff;
        }
      }
    }
  }
}

.order_optimizer_table_row {
  display: flex;
  flex-direction: row;

  &__text {
    color: #fff;
  }

  &__left {
    width: 50%;
    position: relative;
  }

  &__right {
    width: 49%;
    margin-left: 20px;

    &__upper_section {
      margin-bottom: 20px;
      display: flex;
      flex-direction: row;
      &__left {
        width: 50%;
        display: flex;
        flex-direction: row;
      }

      &__right {
        width: 50%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        &__button {
          width: auto;

          &__icon {
            margin-left: 10px;
            * {
              color: #fff;
            }
          }
        }

        &__button_preview {
          width: auto;

          &__icon {
            margin-left: 10px;
            * {
              color: #fff;
            }
          }
        }

        &__badge {
          width: auto;
          max-width: 400px;
          background-color: #d9d9d9;
          color: #000;
          height: 40px;
          border-radius: 20px;
          margin-left: 30px;
          padding: 0 20px;
          line-height: 40px;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      &__text {
        font-size: 30px;
        font-weight: 500;
        width: 100%;
      }
    }

    &__lower_section {
      display: flex;
      flex-direction: row;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &__left {
        width: 50%;
        display: flex;
        flex-direction: row;

        &__item {
          display: flex;
          flex-direction: column;
          width: 50%;

          &__text {
            margin-top: 10px;
            font-size: 20px;
            font-weight: 500;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          &__highlighted {
            color: #3699ff;
          }
        }
      }

      &__right {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        &__text {
          margin-top: 10px;
          font-size: 20px;
          font-weight: 500;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;

    &__left {
      width: 100%;
    }

    &__right {
      width: 100%;
      margin-top: 20px;
      margin-bottom: 20px;

      &__upper_section {
        display: flex;
        flex-direction: column;

        &__left {
          width: auto;
          display: flex;
          flex-direction: row;
        }

        &__right {
          width: auto;
          display: flex;
          flex-direction: row;
          &__button {
            width: auto;

            &__icon {
              margin-left: 10px;
              * {
                color: #fff;
              }
            }
          }

          &__button_preview {
            width: auto;

            &__icon {
              margin-left: 10px;
              * {
                color: #fff;
              }
            }
          }

          &__badge {
            width: auto;
            background-color: #d9d9d9;
            color: #000;
            height: 40px;
            border-radius: 20px;
            margin-left: 10px;
            padding: 0 20px;
            line-height: 40px;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
      &__lower_section {
        display: flex;
        flex-direction: column;

        &__left {
          width: 50%;
          display: flex;
          flex-direction: column;

          &__item {
            display: flex;
            flex-direction: column;
            width: 50%;

            &__text {
              margin-top: 10px;
              font-size: 20px;
              font-weight: 500;
              width: 100%;
            }

            &__highlighted {
              color: #3699ff;
            }
          }
        }

        &__right {
          width: 50%;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;

          &__text {
            margin-top: 10px;
            font-size: 20px;
            font-weight: 500;
            width: 100%;
          }
        }
      }
    }
  }
}
