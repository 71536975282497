.form_error {
  position: relative;
  text-align: justify;
  margin-top: 3px;
  line-height: 0.85;
  text-align: initial;

  &__message {
    font-size: 0.8rem;
    color: $formFieldErrorColor;
  }
}
