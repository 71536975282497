.order_details_history {
  border: 1px solid #ddd;
  border-radius: $borderRadius;
  height: 100%;
  width: 100%;
  position: relative;

  &__heading {
    height: 50px;
    border-bottom: 1px solid #ddd;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
  }

  &__heading_text {
    font-weight: 500;
  }

  .order_details_history_refresh_button {
    &__icon {
      path {
        fill: #fff;
      }
    }
  }

  .order_details_history_loader_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .order_details_history_entry_list {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100% - 50px);
    padding: 10px;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #a9a9b6;
    }

    .order_details_history_entry_list_item {
      border-bottom: 1px solid #ddd;
      &:not(:first-child) {
        padding: 10px 0;
      }

      .order_details_history_entry_list_item_meta {
        display: flex;
      }

      .order_details_history_entry_list_item_date {
        font-size: 12px;
        font-weight: 500;
      }

      .order_details_history_entry_list_item_author {
        font-size: 12px;
        margin-left: 5px;
        font-weight: 500;
        color: $primaryColor;
      }

      .order_details_history_entry_list_item_content {
        &__heading_text {
          text-wrap: wrap;
          font-weight: 500;
          line-height: 1.2rem;
          margin-bottom: 10px;
        }

        &__section {
          margin: 5px 0;
        }

        &__label {
          font-size: 12px;
          text-overflow: ellipsis;
          overflow: hidden;
          font-weight: 500;
        }
        &__value {
          font-size: 12px;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
  }
}
