$cardBackgroundColor: #fff;
$cardPadding: 20px;

.card {
  background-color: $cardBackgroundColor;
  box-shadow: 0px 0px 30px -10px rgb(201, 201, 201);
  border-radius: $borderRadius;
  position: relative;

  .card_header {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 12px 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    &__heading {
      display: flex;
    }

    &__icon {
      margin-right: 8px;
      margin-top: 4px;
      path {
        fill: rgb(95, 95, 95);
      }
    }

    &__title {
      font-size: 1.2rem;
      font-weight: 500;
    }

    &__subtitle {
      font-size: 0.8rem;
      margin-left: 1px;
      color: #797979;
    }

    &__actions {
      display: flex;
    }
  }
  .card_content {
    padding: $cardPadding;
    position: relative;
    &__title {
      font-size: 1.2rem;
      font-weight: 500;
    }
  }

  .card_content_loading_overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($cardBackgroundColor, $highOpacity);
    z-index: $cardLoadingOverlayZIndex;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.card_button {
  padding: 5px 8px;
  width: 28px;
  height: 28px;
  margin-right: 3px;
  position: relative;

  &__icon {
    path {
      fill: #fff;
    }
  }
}

$dashboardCardColor: $fontPrimaryColor;
$dashboardCardActiveColor: #fff;
$dashboardCardBackgroundColor: #fff;
$dashboardCardBackgroundActiveColor: $primaryColor;

.dashboard_card {
  transition: all $transitionTime;
  height: 150px;
  padding: 0;

  .dashboard_card_content {
    position: relative;
    color: #fff;
    padding: 0;
    display: flex;
    width: 100%;
    height: 100%;
  }

  &:hover,
  &:focus {
    background-color: $dashboardCardBackgroundActiveColor;

    .dashboard_card__title {
      color: $dashboardCardActiveColor;
    }

    .dashboard_card__icon {
      path {
        fill: $dashboardCardActiveColor;
      }
    }
  }

  &__title {
    margin-bottom: 8px;
    color: $dashboardCardColor;
    font-weight: 500;
    font-size: 1.2rem;
  }

  &__icon {
    path {
      fill: $dashboardCardColor;
    }
  }

  &__link {
    width: 100%;
    height: 100%;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

$cardKeyValueContentColor: #000000;

.card_key_value {
  margin: 16px 0;

  &__label {
    font-size: 1rem;
    font-weight: 500;
    cursor: default;
  }

  &__content {
    color: $cardKeyValueContentColor;
    margin-top: 3px;
  }
}
