.chart {
  overflow: hidden;
  overflow-x: scroll;
  margin: 0 auto;
  height: 100%;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.1);
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.2);
  }
  &::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.4);
  }

  .VictoryContainer {
    > svg,
    div > svg {
      width: auto !important;
      height: auto !important;
    }
  }
}
