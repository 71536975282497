.sign_up_driver_company_add_address {
  &__message_box {
    display: flex;
    max-height: 40px;
  }

  .sign_up_driver_company_add_address_map_form_field {
    width: 100%;
    height: 380px;
    position: relative;
  }
}
