.delegation_add {
  $selectedPassengerTextColor: $primaryColor;
  $selectedPassengerOnboardPlaceTextColor: #329632;
  $selectedPassengerOutboardPlaceTextColor: #bb4324;
  .delegation_add_passenger_list {
    list-style: none;
  }

  .delegation_add_passenger_list_item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    &:not(:first-child) {
      margin: 10px 0;
    }

    &__drag_button {
      border: none;
      background: none;
      margin-right: 5px;
      padding: 2px;
    }

    &__index {
      width: 30px;
    }

    &__passenger_name {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &.selected {
      .delegation_add_passenger_list_item__index {
        path {
          fill: $selectedPassengerTextColor;
        }
      }

      .delegation_add_passenger_list_item__passenger_name {
        color: $selectedPassengerTextColor;
      }
    }
  }

  .delegation_add_summary {
    &__delegation_number_form_field_content {
      line-break: normal;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &__connection_line {
      left: 19.5px !important;
    }

    &__map_wrapper {
      height: 300px;
      width: 100%;
      position: relative;
    }

    .delegation_add_summary_route {
      .delegation_add_summary_route_waypoint_list {
        &__header {
          display: flex;
          gap: 10px;
        }

        &__heading {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          font-size: 14px;

          &.place {
            flex: 3;
          }

          &.distance {
            flex: 1;
          }
        }

        &__row {
          display: flex;
          gap: 10px;

          * {
            font-size: 14px;
          }
        }

        &__row_cell {
          text-overflow: ellipsis;
          overflow: hidden;

          &.place {
            flex: 3;
            display: flex;
            align-items: center;

            .index {
              width: 10px;
            }

            .dot {
              width: 6px;
              height: 6px;
              background-color: #000;
              border-radius: 100%;
              z-index: 2;
              margin: 0 7px;
            }

            .place_name {
              width: calc(100% - 16px);
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }
          }

          &.distance {
            flex: 1;
          }

          &.time {
            flex: 1;
          }
        }
      }
    }
  }

  .delegation_add_routes_return_address_button {
    margin-left: 10px;
  }

  .delegation_add_route_list_item {
    background-color: #fff;
    margin-bottom: 10px;

    &:not(:first-child) {
      margin: 10px 0;
    }

    &__top_row {
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      border: 1px solid #dddddd;
      padding: 10px 8px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 5px;
    }

    &__drag_button {
      border: none;
      background: none;
      padding: 2px;
    }

    &__index {
      padding: 10px 12px;
    }

    &__address {
      flex: 1;
    }
  }

  .delegation_add_group_list_item {
    background-color: #fff;
    margin-bottom: 10px;

    &:not(:first-child) {
      margin: 10px 0;
    }

    &__top_row {
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      border: 1px solid #dddddd;
      padding: 10px 8px;
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
    }

    &__row {
      display: flex;
      flex-wrap: wrap;
    }

    &__return_button {
      margin-left: 10px;
    }

    &__drag_button {
      border: none;
      background: none;
      padding: 10px 12px;
    }

    &__droppable {
      width: calc(100% - 125px);
    }

    &__space {
      margin-top: 0;
    }

    &__index {
      padding: 10px 12px;
      align-self: center;
    }

    &__address {
      flex: 1;
    }

    &__delete_button {
      height: fit-content;
      align-self: center;
    }
  }

  .delegation_add_passenger_icon {
    position: relative;
    display: flex;
    align-items: center;

    &__content {
      position: absolute;
      left: 0;
      width: 14px;
      font-size: 12px;
      text-align: center;
    }
  }
}

.delegation_add_route_validation_modal {
  &__error_list {
    padding-left: 20px;
  }
}
