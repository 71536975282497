.planning_order {
  &_driver_plan_items {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  &_driver_plan_item {
    border: 1px solid #ddd;
    border-radius: 4px;

    &_details {
      display: flex;
      flex-direction: column;
      border-right: 1px solid #ddd;

      &__heading {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #ddd;
        padding: 8px;
        padding: 6px 8px;
        margin-bottom: 8px;
        font-size: 16px;
        gap: 0 4px;

        span {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        button {
          padding: unset;
        }

        &-icon {
          background-color: #3699ff;
          padding: 8px 10px;
          border-radius: 4px;
          cursor: pointer;

          &:hover {
            background-color: #228af1;
          }
        }

        path {
          fill: #fff;
        }
      }

      &__user {
        display: flex;
        flex-direction: column;
      }

      &__heading {
        border-bottom: 1px solid #ddd;
        padding: 8px;
        font-size: 16px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      &__row {
        font-size: 12px;
        padding: 0 8px;
        margin-bottom: 4px;
        text-overflow: ellipsis;
        overflow: hidden;

        &:first-of-type {
          padding-top: 8px;
        }

        &:last-of-type {
          padding-bottom: 8px;
        }

        strong,
        div {
          display: block;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          font-size: 14px;
        }
      }

      &__route {
        background-color: #f1eeee;
        border-right: 1px solid #ddd;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-size: 14px;
        padding: 8px;
      }
    }
  }

  &_driver_chart {
    @include breakpoint(largescreen) {
      margin-left: -25px;
    }
  }
}
