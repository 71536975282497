@import "./contract-details/contract-details.scss";

.billings {
  .billings_map_wrapper {
    height: 400px;
    width: 100%;
    position: relative;
  }
  .billing_summary_confirmation {
    width: 1280px;
    max-width: 90vw;
  }
}

.route_details {
  .route_details_button_wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }

  &_additional {
    .col-lg-6 {
      justify-content: space-between;
      display: flex;
      flex-direction: column;
    }
  }
}
