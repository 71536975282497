.taxi_cargo_contract_listing {
  &__pagination_wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }

  .driver_contract_listing_tools {
    display: flex;
    justify-content: space-between;
    position: relative;
  }
}
