.status_legend {
  display: flex;
  gap: 0 15px;
  margin: 10px 0 20px 0;

  h5 {
    font-weight: bold;
  }

  &_item {
    display: flex;
    align-items: center;
    gap: 0 5px;

    &_square {
      height: 10px;
      width: 10px;
    }

    &_count {
      font-size: 15px;
    }

    &_planned {
      background-color: $plannedStatusColor;
    }

    &_canidature {
      background-color: $candidatureStatusColor;
    }

    &_in_progress {
      background-color: $inProgressStatusColor;
    }

    &_pending {
      background-color: $pendingStatusColor;
    }

    &_accepted {
      background-color: $acceptedStatusColor;
    }

    &_cancelled {
      background-color: $cancelledStatusColor;
    }

    &_finished {
      background-color: $finishedStatusColor;
    }

    &_created {
      background-color: $createdStatusColor;
    }

    &_rejected {
      background-color: $rejectedStatusColor;
    }

    &_reopened {
      background-color: $reopenedStatusColor;
    }

    &_reopen_request {
      background-color: $reopenRequestStatusColor;
    }

    &_current_plan {
      background-color: $currentPlanStatusColor;
    }

    &_order {
      background-color: $orderStatusColor;
    }

    &_potential_plan {
      background-color: $potentialPlanStatusColor;
    }

    &_proposed_potential_plan {
      background-color: $proposesPotentialStatusColor;
    }

    &_delegation_created {
      background-color: $delegationCreatedStatusColor;
    }

    &_undownloaded {
      background-color: $undownloadedStatusColor;
    }

    &_downloaded {
      background-color: $downloadedStatusColor;
    }

    &_deleted {
      background-color: $deletedStatusColor;
    }
  }
}
